<template>
  <div class="connector" @click="$emit('connect', connector.type)">
    <div class="connector__img">
      <img :src="connector.icon" :alt="connector.type" /> <span class="connector_name">{{ connector.name }}</span>
    </div>
    <!-- <div class="connector__desc">{{ connector.description }}</div> -->
  </div>
</template>

<script>
export default {
  name: "Connector",
  props: {
    connector: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style>
  .connector__img img {
    margin-right:5px;
    color: #0052FF;
  }

  .connector__img {
    color: #0052FF;
    font-size: 20px;
    font-weight: bold;
  }
  .connector {
    background-color: rgba(0, 82, 255, 0.1);
    width:85%;
    margin:20px auto;
  }

  .connector:hover {
    background-color: rgba(0, 82, 255, 0.2);
  }

  @media screen and (max-width: 768px) {
    .connector {
      width:100%;
      padding:15px 0;
    }
  }
</style>